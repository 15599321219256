/** @jsxImportSource @emotion/react */
/* eslint-disable camelcase */
import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { css } from "@emotion/react";

import LoadingDots from "../../LoadingDots";
import InputFeedback from "../../InputFeedback";
import defineAbilityFor from "../../../services/abilities";
import PageHeader from "../../PageHeader";
import UserRoleEditMutation from "../../../mutations/UserRoleEditMutation";
import "./UserRoleForm.scss";

const ROLES = {
  system_technician: {
    name: "Technician Lead",
    description:
      "The system tech is the default case owner and will be CC'd in emails reminding to update cases."
  }
};

class UserRoleForm extends React.PureComponent {
  static propTypes = {
    user: PropTypes.object
  };

  static defaultProps = {
    user: {}
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      errors: [],
      userID: props.userRole.user_id,
      redirectToUsers: false,
      alertMessage: ""
    };
  }

  submitForm = () => {
    const { userRole } = this.props;
    const { userID } = this.state;
    if (!userRole.name) {
      this.setState({
        errors: "noroleid"
      });
      return;
    }
    this.setState({ errors: [], loading: true });

    UserRoleEditMutation(userRole.name, userID, (data, errors) => {
      this.setState({ loading: false });
      if (errors) {
        this.setState({ errors });
      } else {
        this.setState({
          redirectToUsers: true,
          alertMessage: "User Role edited successfully."
        });
      }
    });
  };

  onChange = (e) => {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value
    });
  };

  render() {
    const { user, usersInternal } = this.props;
    const { userID, loading, errors, redirectToUsers, alertMessage } =
      this.state;

    const sortedUsersInternal = usersInternal.slice().sort((a, b) => {
      const locale = "en";
      const options = { numeric: true };
      const termA = `${a.first_name} ${a.last_name}` || "";
      const termB = `${b.first_name} ${b.last_name}` || "";
      return termA.localeCompare(termB, locale, options);
    });

    // Redirect
    if (redirectToUsers) {
      const destination = {
        pathname: "/users/",
        state: { alertMessage }
      };
      return <Redirect to={destination} />;
    }

    // Authorization
    if (defineAbilityFor(user).cannot("edit", "userrole")) {
      return (
        <div>
          <p>You do not have permission to edit user roles.</p>
        </div>
      );
    }

    // Errors
    const noRoleIdError = errors.length > 0 && errors.includes("noroleid");
    const noAuthorizationError =
      errors.length > 0 && errors.includes("unauthorized");

    const role = ROLES[this.props.userRole.name];
    return (
      <div className="UsersRole">
        <PageHeader title="Edit User Role" hideLinks />
        <div className="UsersRoleForm">
          <FormGroup>
            <div class="mb-3">
              <Label>User Role</Label>
              <h5>{role.name}</h5>
            </div>
            <div class="mb-3">
              <Label>Description</Label>
              <p>{role.description}</p>
            </div>
            <Label for="userID">Assigned User</Label>
            <Input
              type="select"
              name="userID"
              id="userID"
              value={userID || ""}
              onChange={this.onChange}
              required
            >
              <option value={0}>None</option>
              {sortedUsersInternal.map((internalUser) => {
                return (
                  <option key={internalUser.id} value={internalUser.node_id}>
                    {`${internalUser.first_name} ${internalUser.last_name} (${internalUser.email})`}
                  </option>
                );
              })}
            </Input>
          </FormGroup>
          {errors.length > 0 && !noRoleIdError && !noAuthorizationError && (
            <FormGroup>
              <InputFeedback valid={errors.length <= 0}>
                You were unable to edit the user.
              </InputFeedback>
            </FormGroup>
          )}
          {noRoleIdError > 0 && (
            <FormGroup>
              <InputFeedback valid={errors.length <= 0}>
                Roles are required to have a value.
              </InputFeedback>
            </FormGroup>
          )}
          {noAuthorizationError && (
            <FormGroup>
              <InputFeedback valid={errors.length <= 0}>
                Your user account is not authorized to perform this action.
              </InputFeedback>
            </FormGroup>
          )}
          <FormGroup>
            <Button
              type="submit"
              color="primary"
              onClick={this.submitForm}
              css={css`
                margin-right: 10px;
              `}
            >
              Save
            </Button>
          </FormGroup>
          <div>
            <div className="mt-5">
              <LoadingDots loading={loading} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserRoleForm;
