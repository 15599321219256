/** @jsxImportSource @emotion/react */
import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/react";
import { Alert as AlertBase } from "reactstrap";

class Alert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true
    };

    this.onDismiss = this.onDismiss.bind(this);

    setTimeout(() => {
      this.setState({ visible: false });
    }, 5000);
  }

  onDismiss() {
    this.setState({ visible: false });
  }

  render() {
    const { color, message } = this.props;
    const { visible } = this.state;
    return (
      <div>
        <AlertBase
          color={color}
          css={css`
            position: fixed;
            top: 15%;
            z-index: 100;
            left: 15%;
            width: 70%;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
          `}
          isOpen={visible}
          toggle={this.onDismiss}
        >
          {message}
        </AlertBase>
      </div>
    );
  }
}
Alert.defaultProps = {
  color: "success"
};

Alert.propTypes = {
  color: PropTypes.string,
  message: PropTypes.string.isRequired
};

export default Alert;
