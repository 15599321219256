import React from "react";
import { Link } from "react-router-dom";

import "./HeaderLinks.scss";

/**
 * Returns true if start path matches url path
 * @param {String} path url path
 * @param {String} start expected url path start
 */
function activeUrlPath(path, start) {
  return path.startsWith(start) ? "activeLink" : "";
}

export default function HeaderLinks() {
  const url = new RegExp(
    /^(([^:/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/
  ).exec(document.URL);
  const path = url[5];
  return (
    <div className="HeaderLinks">
      <Link to="/kb" className={activeUrlPath(path, "/kb")}>
        <img src="/img/icn-book-sml.svg" alt="Knowledge Base" border="0" />
        <span className="tooltiptext">Knowledge Base</span>
      </Link>
      <Link to="/cases" className={activeUrlPath(path, "/cases")}>
        <img src="/img/icn-folder-sml.svg" alt="Open Cases" border="0" />
        <span className="tooltiptext">Open Cases</span>
      </Link>
      <Link
        to="/documents?category=Bulletin"
        className={activeUrlPath(path, "/documents")}
      >
        <img src="/img/icn-megaphone.svg" alt="Service Bulletins" border="0" />
        <span className="tooltiptext">Service Bulletins</span>
      </Link>
      <Link to="/alerts" className={activeUrlPath(path, "/alerts")}>
        <img src="/img/icn-alert.svg" alt="Alerts" border="0" />
        <span className="tooltiptext">Alerts</span>
      </Link>
    </div>
  );
}
