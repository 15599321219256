import { AbilityBuilder, Ability } from "@casl/ability";

export const usergroups = [
  "pmp_administrator",
  "pmp_technician",
  "site_user",
  "site_administrator"
];

/**
 * @param {{usergroup:typeof usergroups[number]}} user
 */
export default function defineAbilityFor(user) {
  const { can, cannot, build } = new AbilityBuilder(Ability);
  if (!user) {
    // This exists for 400&500 pages, as we aren't able to pass in the
    // usergroup as a prop, so we basically just allow them to navigate back to the
    // dashboard, where all of their abilities will be restored
    return build();
  }
  if (user.usergroup === "pmp_administrator") {
    can("view", "all");
    can("edit", "all");
    can("delete", "all");
    can("goto", "all");

    // Hidden Features
    can("view", "hiddenFeature");

    // Customers
    can("view", "customers");
    can("add", "customers");
    can("edit", "customers");
    can("archive", "customers");

    // Locations
    can("view", "locations");
    can("add", "locations");
    can("edit", "locations");
    can("delete", "locations");

    // Users
    can("view", "users");
    can("add", "users");
    can("edit", "users");
    can("fullEdit", "users");
    can("delete", "users");
    can("edit", "userrole");

    // Resources
    can("view", "resources");
    can("viewAll", "resources");
    can("add", "resources");
    can("edit", "resources");
    can("delete", "resources");

    // Password
    can("view", "password");
    can("add", "password");
    can("edit", "password");
    can("delete", "password");

    // Documents
    can("view", "documents");
    can("add", "documents");
    can("edit", "documents");
    can("delete", "documents");
    can("forcedelete", "documents");

    // Inspections
    can("add", "inspections");
    can("edit", "inspections");
    can("delete", "inspections");
    can("view", "inspections");
    can("goto", "/resources/:resourceID/inspection/add");
    can("goto", "/resources/:resourceID/inspection/:inspectionID");

    // Articles
    can("view", "articles");
    can("viewNotes", "articles");
    can("viewState", "articles");
    can("add", "articles");
    can("edit", "articles");
    can("delete", "articles");
    can("goto", "/kb/article/add");
    can("goto", "/kb/article/:articleID");
    can("goto", "/kb/article/:articleID/edit");

    // KB Categories
    can("view", "kbCategories");

    // Parts
    can("view", "parts");

    // Cases
    can("view", "cases");
    can("viewAll", "cases");
    can("add", "cases");
    can("edit", "cases");
    can("delete", "cases");
    can("assign", "cases");
    can("goto", "/case/add");
    can("goto", "/case/:caseID");
    can("goto", "/case/:caseID/edit");
    can("loadOpenOnly", "cases");

    // Alerts
    can("add", "alerts");
    can("edit", "alerts");

    // Notes
    can("add", "notes");
    can("edit", "notes");
    can("delete", "notes");
    can("goto", "/resources/:resourceID/note/add");
    can("goto", "/resources/:resourceID/note/:noteID");

    // Components
    can("add", "components");
    can("edit", "components");
    can("delete", "components");
    can("view", "components");
    can("goto", "/resources/:resourceID/component/add");
    can("goto", "/resources/:resourceID/component/:componentID");

    // Users Internal
    can("view", "usersInternals");
    can("add", "usersInternal");
    can("edit", "usersInternal");
    can("delete", "usersInternal");
    can("goto", "/users");
    can("goto", "/user/:userID");

    // Software
    can("view", "softwares");
    can("add", "softwares");
    can("edit", "softwares");
    can("delete", "softwares");
    can("attach", "softwares");
    can("detach", "softwares");
    can("goto", "/software");
    can("goto", "/software/:softwareID");
    can("goto", "/resource/:resourceID/software/add");
    can("goto", "/resource/:resourceID/software/:softwareID");

    // Audits
    can("view", "audits");
  }
  if (user.usergroup === "pmp_technician") {
    can("view", "all");
    can("goto", "all");

    // Hidden Features
    can("view", "hiddenFeature");

    // Customers
    can("view", "customers");
    cannot("add", "customers");
    cannot("edit", "customers");
    cannot("archive", "customers");

    // Locations
    can("view", "locations");
    can("add", "locations");
    can("edit", "locations");
    can("delete", "locations");

    // Users
    can("view", "users");
    can("add", "users");
    can("edit", "users");
    can("fullEdit", "users");
    can("delete", "users");
    cannot("edit", "userrole");

    // Resources
    can("view", "resources");
    can("viewAll", "resources");
    can("add", "resources");
    can("edit", "resources");
    can("delete", "resources");

    // Password
    can("view", "password");
    can("add", "password");
    can("edit", "password");
    cannot("delete", "password");

    // Documents
    can("view", "documents");
    can("add", "documents");
    can("edit", "documents");
    can("delete", "documents");
    can("goto", "/documents");
    can("goto", "/document/:documentID");
    cannot("forcedelete", "documents");

    // Inspections
    can("add", "inspections");
    can("edit", "inspections");
    can("view", "inspections");
    cannot("delete", "inspections");
    can("goto", "/resources/:resourceID/inspection/add");
    can("goto", "/resources/:resourceID/inspection/:inspectionID");

    // Components
    can("add", "components");
    can("edit", "components");
    can("delete", "components");
    can("view", "components");
    can("goto", "/resources/:resourceID/component/add");
    can("goto", "/resources/:resourceID/component/:componentID");

    // Users Internal
    cannot("view", "usersInternals");
    cannot("add", "usersInternal");
    cannot("edit", "usersInternal");
    cannot("delete", "usersInternal");
    cannot("goto", "/users");
    cannot("goto", "/user/add");
    cannot("goto", "/user/:userID");

    // Software
    can("view", "softwares");
    can("add", "softwares");
    can("edit", "softwares");
    can("delete", "softwares");
    can("attach", "softwares");
    can("detach", "softwares");
    can("goto", "/software");
    can("goto", "/software/:softwareID");
    can("goto", "/resource/:resourceID/software/add");
    can("goto", "/resource/:resourceID/software/:softwareID");

    // Articles
    can("view", "articles");
    can("viewNotes", "articles");
    can("viewState", "articles");
    can("add", "articles");
    can("edit", "articles");
    can("delete", "articles");
    can("goto", "/kb/article/add");
    can("goto", "/kb/article/:articleID");
    can("goto", "/kb/article/:articleID/edit");

    // KB Categories
    can("view", "kbCategories");

    // Parts
    can("view", "parts");

    // Cases
    can("view", "cases");
    can("viewAll", "cases");
    can("add", "cases");
    can("edit", "cases");
    can("delete", "cases");
    can("assign", "cases");
    can("goto", "/case/add");
    can("goto", "/case/:caseID");
    can("goto", "/case/:caseID/edit");
    can("loadOpenOnly", "cases");

    // Alerts
    can("add", "alerts");
    can("edit", "alerts");

    // Notes
    can("add", "notes");
    can("edit", "notes");
    can("delete", "notes");
    can("goto", "/resources/:resourceID/note/add");
    can("goto", "/resources/:resourceID/note/:noteID");

    // Audits
    can("view", "audits");
  }
  if (user.usergroup === "site_user") {
    can("goto", "all");

    // Hidden Features
    cannot("view", "hiddenFeature");

    // Customers
    cannot("goto", "/customers/");
    cannot("goto", "/customer/:customerID");
    cannot("view", "customers");
    cannot("add", "customers");
    cannot("edit", "customers");
    cannot("archive", "customers");
    cannot("goto", "/customers/add");

    // Locations
    cannot("view", "locations");
    cannot("add", "locations");
    cannot("edit", "locations");
    cannot("delete", "locations");
    cannot("goto", "/customer/:customerID/location/add");
    cannot("goto", "/customer/:customerID/location/:locationID");

    // Users
    cannot("view", "users");
    cannot("add", "users");
    cannot("edit", "users");
    cannot("delete", "users");
    cannot("goto", "/customer/:customerID/user/add");
    cannot("goto", "/customer/:customerID/user/:userID");
    cannot("edit", "userrole");

    // Resources
    can("view", "resources");
    cannot("viewAll", "resources");
    cannot("add", "resources");
    cannot("edit", "resources"); // This does not prevent site_users from editing status
    cannot("delete", "resources");

    // Password
    cannot("view", "password");
    cannot("add", "password");
    cannot("edit", "password");
    cannot("delete", "password");

    // Documents
    can("view", "documents");
    cannot("add", "documents");
    cannot("edit", "documents");
    cannot("delete", "documents");
    cannot("forcedelete", "documents");
    cannot("goto", "/documents");
    cannot("goto", "/document/:documentID");

    // Inspections
    cannot("add", "inspections");
    cannot("edit", "inspections");
    cannot("delete", "inspections");
    can("view", "inspections");
    cannot("goto", "/resources/:resourceID/inspection/add");
    cannot("goto", "/resources/:resourceID/inspection/:inspectionID");

    // Components
    cannot("add", "components");
    cannot("edit", "components");
    cannot("delete", "components");
    can("view", "components");
    cannot("goto", "/resources/:resourceID/component/add");
    cannot("goto", "/resources/:resourceID/component/:componentID");

    // Users Internal
    cannot("view", "usersInternals");
    cannot("add", "usersInternal");
    cannot("edit", "usersInternal");
    cannot("delete", "usersInternal");
    cannot("goto", "/users");
    cannot("goto", "/user/add");
    cannot("goto", "/user/:userID");

    // Software
    can("view", "softwares");
    cannot("add", "softwares");
    cannot("edit", "softwares");
    cannot("delete", "softwares");
    cannot("attach", "softwares");
    cannot("detach", "softwares");
    cannot("goto", "/software");
    cannot("goto", "/software/:softwareID");
    cannot("goto", "/resource/:resourceID/software/add");
    cannot("goto", "/resource/:resourceID/software/:softwareID");

    // Articles
    can("view", "articles");
    cannot("viewNotes", "articles");
    cannot("viewState", "articles");
    cannot("add", "articles");
    cannot("edit", "articles");
    cannot("delete", "articles");
    cannot("goto", "/kb/article/add");
    can("goto", "/kb/article/:articleID");
    cannot("goto", "/kb/article/:articleID/edit");

    // KB Categories
    can("view", "kbCategories");

    // Parts
    can("view", "parts");

    // Cases
    can("view", "cases");
    cannot("viewAll", "cases");
    can("add", "cases");
    cannot("edit", "cases");
    cannot("delete", "cases");
    cannot("assign", "cases");
    can("goto", "/case/add");
    can("goto", "/case/:caseID");
    cannot("goto", "/case/:caseID/edit");
    cannot("loadOpenOnly", "cases");

    // Alerts
    cannot("add", "alerts");
    cannot("edit", "alerts");

    // Notes
    cannot("add", "notes");
    cannot("edit", "notes");
    cannot("delete", "notes");
    cannot("goto", "/resources/:resourceID/note/add");
    cannot("goto", "/resources/:resourceID/note/:noteID");

    // Audits
    cannot("view", "audits");
  }
  if (user.usergroup === "site_administrator") {
    can("goto", "all");

    // Hidden Features
    cannot("view", "hiddenFeature");

    // Customers
    cannot("goto", "/customers/");
    cannot("goto", "/customer/:customerID");
    cannot("view", "customers");
    cannot("add", "customers");
    cannot("edit", "customers");
    cannot("archive", "customers");
    cannot("goto", "/customers/add");

    // Locations
    can("view", "locations");
    cannot("add", "locations");
    cannot("edit", "locations");
    cannot("delete", "locations");
    cannot("goto", "/customer/:customerID/location/add");
    cannot("goto", "/customer/:customerID/location/:locationID");
    can("goto", "PathMatch", {
      path: "/customer/:customerID/location/:locationID",
      "params.customerID": user.customer_id,
      "params.locationID": { $in: user.locations.map((x) => x.node_id) }
    });

    // Users
    can("view", "users");
    cannot("add", "users");
    can("edit", "users");
    cannot("fullEdit", "users");
    cannot("delete", "users");
    cannot("edit", "userrole");
    cannot("goto", "/customer/:customerID/user/add");
    cannot("goto", "/customer/:customerID/user/:userID");
    can("goto", "PathMatch", {
      path: "/customer/:customerID/user/:userID",
      "params.customerID": user.customer_id
    });

    // Resources
    can("view", "resources");
    cannot("viewAll", "resources");
    cannot("add", "resources");
    cannot("edit", "resources"); // This does not prevent site_users from editing status
    cannot("delete", "resources");

    // Password
    cannot("view", "password");
    cannot("add", "password");
    cannot("edit", "password");
    cannot("delete", "password");

    // Documents
    can("view", "documents");
    cannot("add", "documents");
    cannot("edit", "documents");
    cannot("delete", "documents");
    cannot("forcedelete", "documents");
    cannot("goto", "/documents");
    cannot("goto", "/document/:documentID");

    // Inspections
    cannot("add", "inspections");
    cannot("edit", "inspections");
    cannot("delete", "inspections");
    can("view", "inspections");
    cannot("goto", "/resources/:resourceID/inspection/add");
    cannot("goto", "/resources/:resourceID/inspection/:inspectionID");

    // Components
    cannot("add", "components");
    cannot("edit", "components");
    cannot("delete", "components");
    can("view", "components");
    cannot("goto", "/resources/:resourceID/component/add");
    cannot("goto", "/resources/:resourceID/component/:componentID");

    // Users Internal
    cannot("view", "usersInternals");
    cannot("add", "usersInternal");
    cannot("edit", "usersInternal");
    cannot("delete", "usersInternal");
    cannot("goto", "/users");
    cannot("goto", "/user/add");
    cannot("goto", "/user/:userID");

    // Software
    can("view", "softwares");
    cannot("add", "softwares");
    cannot("edit", "softwares");
    cannot("delete", "softwares");
    cannot("attach", "softwares");
    cannot("detach", "softwares");
    cannot("goto", "/software");
    cannot("goto", "/software/:softwareID");
    cannot("goto", "/resource/:resourceID/software/add");
    cannot("goto", "/resource/:resourceID/software/:softwareID");

    // Articles
    can("view", "articles");
    cannot("viewNotes", "articles");
    cannot("viewState", "articles");
    cannot("add", "articles");
    cannot("edit", "articles");
    cannot("delete", "articles");
    cannot("goto", "/kb/article/add");
    can("goto", "/kb/article/:articleID");
    cannot("goto", "/kb/article/:articleID/edit");

    // KB Categories
    can("view", "kbCategories");

    // Parts
    can("view", "parts");

    // Cases
    can("view", "cases");
    cannot("viewAll", "cases");
    can("add", "cases");
    cannot("edit", "cases");
    cannot("delete", "cases");
    cannot("assign", "cases");
    can("goto", "/case/add");
    can("goto", "/case/:caseID");
    cannot("goto", "/case/:caseID/edit");
    cannot("loadOpenOnly", "cases");

    // Alerts
    cannot("add", "alerts");
    cannot("edit", "alerts");

    // Notes
    cannot("add", "notes");
    cannot("edit", "notes");
    cannot("delete", "notes");
    cannot("goto", "/resources/:resourceID/note/add");
    cannot("goto", "/resources/:resourceID/note/:noteID");

    // Audits
    cannot("view", "audits");
  }
  if (
    user.usergroup === "site_administrator" ||
    user.usergroup === "site_user"
  ) {
    if (!user.enablement_cases) {
      cannot("add", "cases");
      cannot("edit", "cases");
      cannot("delete", "cases");
      cannot("assign", "cases");
      cannot("goto", "/case/add");
      cannot("goto", "/case/:caseID/edit");
    }
    if (!user.enablement_catalog) {
      cannot("view", "parts");
    }
    if (!user.enablement_training) {
      // TODO: FIXME: determine what this is for
    }
  }
  return build();
}
