import React from "react";

import p from "../../../package.json";

import "./Footer.scss";

const { version } = p;

class Footer extends React.PureComponent {
  render() {
    return (
      <div className="Footer">
        <div className="Logos">
          <a
            href="https://rokion.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="rokion" src="/img/rokion-logo.svg" alt="Rokion" />
          </a>
          <a
            href="https://prairiemachine.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/img/Prairie_Machine_Logo_V_RGB_181112.svg"
              alt="Prairie Machine"
            />
          </a>
        </div>
        <div className="Copyright">
          {`Prairie Machine ${new Date().getFullYear()} `}
          <span className="Version">{version}</span>
        </div>
      </div>
    );
  }
}

export default Footer;
