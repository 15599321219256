/* eslint-disable react/prop-types */
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";

import LoadingDots from "../LoadingDots";
import auth from "../../services/auth";
import Navigation from "../Navigation";
import environment from "../../Environment";
import defineAbilityFor from "../../services/abilities";
import Footer from "../Footer";

class PathMatch {
  constructor(match) {
    this.path = match.path;
    this.params = match.params;
    this.isExact = match.isExact;
    this.url = match.url;
  }
}

const ServiceRoute = ({ component: Component, isPrivate, ...rest }) => {
  if (isPrivate && !auth.isAuthenticated) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )}
      />
    );
  }

  return (
    <div>
      <QueryRenderer
        environment={environment}
        query={graphql`
          query ServiceRouteQuery($token: String!) {
            userByToken(token: $token) {
              id
              node_id
              usergroup
              customer_id
              epicor_id
              sales_rep_code
              first_name
              last_name
              enablement_cases
              enablement_catalog
              enablement_training
              locations {
                node_id
              }
            }
          }
        `}
        variables={{
          token: localStorage.getItem("token")
        }}
        render={({ error, props: queryProps }) => {
          if (error) {
            return <div>{error.message}</div>;
          }
          if (queryProps && queryProps.userByToken !== null) {
            // Check user's ability if they can visit this route or not
            const ability = defineAbilityFor(queryProps.userByToken);
            const path = new PathMatch(rest.computedMatch);
            if (
              ability.cannot("goto", rest.path) &&
              ability.cannot("goto", path)
            ) {
              return (
                <Route
                  {...rest}
                  render={() => (
                    <Redirect
                      to={{
                        pathname: "/403",
                        state: { user: queryProps.userByToken }
                      }}
                    />
                  )}
                />
              );
            }
            // Return the Route, with nav & Container for styling
            return (
              <>
                <Navigation user={queryProps.userByToken} />
                <Container className="container-fluid">
                  <Route
                    {...rest}
                    render={(props) => (
                      <Component {...props} user={queryProps.userByToken} />
                    )}
                  />
                  <Footer />
                </Container>
              </>
            );
          }
          return (
            <div className="ServicePagePulseLoader">
              <LoadingDots loading color="#0000af" size={20} />
            </div>
          );
        }}
      />
    </div>
  );
};

export default ServiceRoute;
