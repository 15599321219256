import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./containers/Login";
import ServiceRoute from "./components/ServiceRoute";
import ForceLogoutRoute from "./components/ForceLogoutRoute";
import AppBoundary from "./components/Boundary";
import GoogleAnalytics from "./GoogleAnalytics";
import {
  Dashboard,
  ResourceDashboard,
  FourOhThree,
  Customers,
  CustomersAdd,
  CustomerEdit,
  Locations,
  LocationAdd,
  LocationEdit,
  UserAdd,
  UserEdit,
  ResourceAdd,
  ResourceEdit,
  FourOhFour,
  FourHundred,
  RequestService,
  Parts,
  KnowledgeBase,
  Alerts,
  AlertAdd,
  AlertView,
  AlertEdit,
  ArticleAdd,
  ArticleEdit,
  Article,
  Case,
  CaseAdd,
  CaseEdit,
  CasesPage,
  Documents,
  DocumentEdit,
  EnterpriseSearch,
  FleetOverview,
  ResetPassword,
  NewPassword,
  InspectionEdit,
  InspectionAdd,
  UsersInternal,
  UserInternalAdd,
  UserInternalEdit,
  LiveLync,
  ServicePage,
  ResourcePasswordAdd,
  ResourcePasswordEdit,
  Softwares,
  SoftwareAdd,
  SoftwareEdit,
  TileDashboard,
  ResourceSoftwareAdd,
  ResourceSoftwareEdit,
  ComponentAdd,
  ComponentEdit,
  NoteAdd,
  NoteEdit
} from "./services/codeSplit";
import "./App.scss";
import UserRoleEdit from "./containers/UserRoles/UserRoleEdit";

export default function App() {
  return (
    <AppBoundary>
      <Router>
        <Switch>
          <ForceLogoutRoute path="/login" component={Login} />
          <Route path="/403" component={FourOhThree} />
          <Route path="/400" component={FourHundred} />
          <ForceLogoutRoute path="/reset" component={ResetPassword} exact />
          <ForceLogoutRoute
            path="/newpassword/:resetToken"
            component={NewPassword}
            exact
          />
          <ForceLogoutRoute
            path="/newpassword/"
            component={NewPassword}
            exact
          />
          <ServiceRoute
            path="/entirefleet"
            component={Dashboard}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/resource/:resourceID"
            component={ResourceDashboard}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/resource/:resourceID/servicerequest"
            component={RequestService}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/resource/:resourceID/inspection/add"
            component={InspectionAdd}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/resource/:resourceID/inspection/:inspectionID"
            component={InspectionEdit}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/customers/"
            component={Customers}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/customers/add"
            component={CustomersAdd}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/customer/:customerID"
            component={CustomerEdit}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/customer/:customerID/locations"
            component={Locations}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/customer/:customerID/location/add"
            component={LocationAdd}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/customer/:customerID/location/:locationID"
            component={LocationEdit}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/customer/:customerID/user/add"
            component={UserAdd}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/customer/:customerID/user/:userID"
            component={UserEdit}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/customer/:customerID/resource/add"
            component={ResourceAdd}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/customer/:customerID/resource/:resourceID"
            component={ResourceEdit}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/documents/"
            component={Documents}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/document/:documentID"
            component={DocumentEdit}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/users"
            component={UsersInternal}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/userrole/:userroleID"
            component={UserRoleEdit}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/user/add"
            component={UserInternalAdd}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/user/:userID"
            component={UserInternalEdit}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/service/"
            component={ServicePage}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/software/"
            component={Softwares}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/software/add"
            component={SoftwareAdd}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/software/:softwareID"
            component={SoftwareEdit}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/resource/:resourceID/software/add"
            component={ResourceSoftwareAdd}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/resource/:resourceID/software/:softwareInstallID"
            component={ResourceSoftwareEdit}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/resource/:resourceID/component/add"
            component={ComponentAdd}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/resource/:resourceID/component/:componentID"
            component={ComponentEdit}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/resource/:resourceID/note/add"
            component={NoteAdd}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/resource/:resourceID/note/:noteID"
            component={NoteEdit}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/resource/:resourceID/password/add"
            component={ResourcePasswordAdd}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/resource/:resourceID/password/:passwordID"
            component={ResourcePasswordEdit}
            exact
            isPrivate
          />
          <ServiceRoute path="/livelync/" component={LiveLync} isPrivate />
          <ServiceRoute path="/kb/" component={KnowledgeBase} exact isPrivate />
          <ServiceRoute
            path="/kb/article/add"
            component={ArticleAdd}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/kb/article/:articleID/edit"
            component={ArticleEdit}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/kb/article/:articleID"
            component={Article}
            exact
            isPrivate
          />
          <ServiceRoute path="/cases" component={CasesPage} exact isPrivate />
          <ServiceRoute path="/case/add" component={CaseAdd} exact isPrivate />
          <ServiceRoute path="/case/:caseID" component={Case} exact isPrivate />
          <ServiceRoute
            path="/case/:caseID/edit"
            component={CaseEdit}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/fleet"
            component={FleetOverview}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/fleet/:resourceID"
            component={FleetOverview}
            exact
            isPrivate
          />
          <ServiceRoute path="/" component={TileDashboard} exact isPrivate />
          <ServiceRoute
            path="/search"
            component={EnterpriseSearch}
            exact
            isPrivate
          />
          <ServiceRoute path="/alerts" component={Alerts} exact isPrivate />
          <ServiceRoute
            path="/alert/add"
            component={AlertAdd}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/alert/:alertID"
            component={AlertView}
            exact
            isPrivate
          />
          <ServiceRoute
            path="/alert/:alertID/edit"
            component={AlertEdit}
            exact
            isPrivate
          />
          <ServiceRoute path="/parts" component={Parts} exact isPrivate />
          <ServiceRoute component={FourOhFour} />
        </Switch>
        <GoogleAnalytics />
      </Router>
    </AppBoundary>
  );
}
