import React from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import "./EnterpriseSearchBar.scss";

export default class EnterpriseSearchBar extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    className: PropTypes.string,
    default: PropTypes.string
  };

  static defaultProps = {
    className: "",
    default: ""
  };

  constructor(props) {
    super(props);
    this.state = {
      search: props.default
    };
  }

  setSearch = (e) => {
    const { value } = e.target;
    this.setState({ search: value });
  };

  search = (e) => {
    const { search } = this.state;
    if (e.key === "Enter" && search) {
      const { history } = this.props;
      history.push(`/search?=${search}`);
    }
  };

  render() {
    const { search } = this.state;
    const { className } = this.props;
    return (
      <div className={`EnterpriseSearchBar ${className}`}>
        <Input
          value={search}
          type="search"
          onKeyPress={this.search}
          onChange={this.setSearch}
        />
      </div>
    );
  }
}
