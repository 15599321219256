const auth = {
  isAuthenticated: localStorage.getItem("token") !== null,
  authenticate(token) {
    this.isAuthenticated = true;
    localStorage.setItem("token", token);
  },
  signout() {
    this.isAuthenticated = false;
    localStorage.removeItem("token");
  }
};

export default auth;
