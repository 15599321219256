/** @jsxImportSource @emotion/react */
import React from "react";
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem
} from "reactstrap";
import { css } from "@emotion/react";
import * as Sentry from "@sentry/browser";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { Can } from "@casl/react";
// import PrivateRoute from "./components/PrivateRoute";

import auth from "../../services/auth";
import defineAbilityFor from "../../services/abilities";
import EnterpriseSearchBar from "../EnterpriseSearchBar";
import "./Navigation.scss";

class Navigation extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    usergroup: PropTypes.string
  };

  static defaultProps = {
    usergroup: ""
  };

  constructor(props) {
    super(props);

    this.buttonStyle = css`
      display: inline;
      border: 0;
      background: transparent;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.5);
      padding: 0.5rem 0;

      &:hover {
        color: rgba(0, 0, 0, 0.7);
      }
    `;

    this.state = {
      helpBarCollapsed: true,
      collapsed: true
    };
    props.history.listen(() => {
      this.setState({ collapsed: true });
    });
  }

  toggleHelpBar = () => {
    const { helpBarCollapsed } = this.state;
    this.setState({
      collapsed: true,
      helpBarCollapsed: !helpBarCollapsed
    });
  };

  toggleNavbar = () => {
    const { collapsed } = this.state;
    this.setState({
      helpBarCollapsed: true,
      collapsed: !collapsed
    });
  };

  signOut = () => {
    auth.signout();
    const { history } = this.props;
    history.push("/login");
  };

  triggerSentry = () => {
    this.toggleNavbar();
    Sentry.captureException("Manual Feedback");
    Sentry.showReportDialog({
      title: "Report Bug",
      subtitle: "Please describe the issue that you're having below.",
      subtitle2: "",
      labelSubmit: "Submit"
    });
  };

  triggerSuggestion = () => {
    this.toggleNavbar();
    Sentry.captureException("Suggestion");
    Sentry.showReportDialog({
      title: "Suggestion",
      subtitle: "What is your suggestion?",
      subtitle2: "",
      labelSubmit: "Submit",
      labelComments: "Suggestion"
    });
  };

  render() {
    const { user, history, location } = this.props;
    const { helpBarCollapsed, collapsed } = this.state;

    // eslint-disable-next-line compat/compat
    const searchValue = new URLSearchParams(location.search).get("") || "";

    // NOTE: This is a workaround for migrating old users to follow the new format
    if (this.props.usergroup) {
      this.signOut();
    }
    const ability = defineAbilityFor(user);

    return (
      <div>
        <Navbar light color="light">
          <Container>
            <NavbarBrand tag="div" className="mr-auto">
              <Link to="/">
                <img
                  src="/img/TC-Full-Logo-3.png"
                  alt="TechCenter powered by Rokion and Prairie Machine"
                />
              </Link>
            </NavbarBrand>
            <NavbarToggler
              className={`navbar-toggler-mobile ${
                !collapsed && "navbar-toggler-open"
              }`}
              onClick={this.toggleNavbar}
            />
            <EnterpriseSearchBar
              className="navbar-toggler-web"
              history={history}
              default={searchValue}
            />
            <NavbarToggler
              className="navbar-toggler-web"
              onClick={this.toggleNavbar}
            >
              <span className="navbar-text">MENU</span>
            </NavbarToggler>
            <NavbarToggler
              className="navbar-toggler-web"
              onClick={this.toggleHelpBar}
            >
              <span className="navbar-text">HELP</span>
            </NavbarToggler>
            <Collapse isOpen={!helpBarCollapsed} navbar>
              <Nav navbar>
                <NavItem className="text-right">
                  <Link
                    href="/kb/article/88"
                    to="/kb/article/88"
                    className="nav-link"
                    onClick={this.toggleNavbar}
                  >
                    1. Getting Around Techcenter
                  </Link>
                </NavItem>
                <NavItem className="text-right">
                  <Link
                    href="/kb/article/116"
                    to="/kb/article/116"
                    className="nav-link"
                    onClick={this.toggleNavbar}
                  >
                    2. How to File a Service Case
                  </Link>
                </NavItem>
                <NavItem className="text-right">
                  <Link
                    href="/kb/article/117"
                    to="/kb/article/117"
                    className="nav-link"
                    onClick={this.toggleNavbar}
                  >
                    3. How to Use the TechCenter Parts Catalogue
                  </Link>
                </NavItem>
              </Nav>
            </Collapse>
            <Collapse isOpen={!collapsed} navbar>
              <EnterpriseSearchBar
                className="navbar-toggler-mobile"
                history={history}
                default={searchValue}
              />
              <Nav navbar>
                <Can do="view" a="customers" ability={ability}>
                  <NavItem className="text-right">
                    <Link
                      href="/customers/"
                      to="/customers/"
                      className="nav-link"
                      onClick={this.toggleNavbar}
                    >
                      Customers
                    </Link>
                  </NavItem>
                </Can>
                <Can not do="view" a="customers" ability={ability}>
                  <Can do="view" a="locations" ability={ability}>
                    <NavItem className="text-right">
                      <Link
                        href={`/customer/${user.customer_id}/locations/`}
                        to={`/customer/${user.customer_id}/locations/`}
                        className="nav-link"
                        onClick={this.toggleNavbar}
                      >
                        Locations
                      </Link>
                    </NavItem>
                  </Can>
                </Can>
                <Can do="goto" a="/documents" ability={ability}>
                  <NavItem className="text-right">
                    <Link
                      href="/documents/"
                      to="/documents/"
                      className="nav-link"
                      onClick={this.toggleNavbar}
                    >
                      Documents
                    </Link>
                  </NavItem>
                </Can>
                <Can do="goto" a="/users" ability={ability}>
                  <NavItem className="text-right">
                    <Link
                      href="/users/"
                      to="/users/"
                      className="nav-link"
                      onClick={this.toggleNavbar}
                    >
                      Users
                    </Link>
                  </NavItem>
                </Can>
                <Can do="goto" a="/software" ability={ability}>
                  <NavItem className="text-right">
                    <Link
                      href="/software/"
                      to="/software/"
                      className="nav-link"
                      onClick={this.toggleNavbar}
                    >
                      Software
                    </Link>
                  </NavItem>
                </Can>
                <NavItem className="text-right">
                  <Link
                    href="/service/"
                    to="/service/"
                    className="nav-link"
                    onClick={this.toggleNavbar}
                  >
                    Service Portal
                  </Link>
                </NavItem>
                <NavItem className="text-right hide-on-mobile">
                  <Link
                    href="/livelync/"
                    to="/livelync/"
                    className="nav-link"
                    onClick={this.toggleNavbar}
                  >
                    LiveLync
                  </Link>
                </NavItem>
                <NavItem className="text-right">
                  <button
                    type="button"
                    onClick={this.triggerSuggestion}
                    css={this.buttonStyle}
                  >
                    Suggestion
                  </button>
                </NavItem>
                <NavItem className="text-right">
                  <button
                    type="button"
                    onClick={this.triggerSentry}
                    css={this.buttonStyle}
                  >
                    Report Bug
                  </button>
                </NavItem>
                <NavItem className="text-right">
                  <button
                    type="button"
                    onClick={this.signOut}
                    css={this.buttonStyle}
                  >
                    Sign Out
                  </button>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}

export default withRouter(Navigation);
