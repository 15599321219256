/* eslint-disable */
import { Environment, Network, RecordSource, Store } from "relay-runtime";
import auth from "./services/auth";
import ServerError from "./services/ServerError";

function fetchQuery(operation, variables) {
  return fetch(`${process.env.REACT_APP_API_URL}/graphql`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`
    },
    body: JSON.stringify({
      query: operation.text,
      variables
    })
  })
    .then(response => response.json())
    .then(response => { 
      if (response.errors) {
        const errors = response.errors;
        let unauthorized = false;

        for (let i = 0; i < errors.length; i ++) {
          if (errors[i].message === "Unauthorized") {
            unauthorized = true;
          }
        }
        if (unauthorized) {
          auth.signout();
          window.location.replace("/login");
        }
        else {
          throw new ServerError(response.errors[0]);
        }
      }
      return response;
    });
}

const network = Network.create(fetchQuery);
const source = new RecordSource();
const store = new Store(source);

export default new Environment({
  network,
  store
});
