import React from "react";
import PropTypes from "prop-types";
import PulseLoader from "react-spinners/PulseLoader";

class LoadingDots extends React.PureComponent {
  static propTypes = {
    loading: PropTypes.bool.isRequired
  };

  render() {
    const { loading } = this.props;

    return (
      <div className="PulseLoader">
        <PulseLoader loading={loading} color="#0000af" size={20} />
      </div>
    );
  }
}

export default LoadingDots;
