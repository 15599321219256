// 1
import { commitMutation } from "react-relay";
import graphql from "babel-plugin-relay/macro";

// import { ConnectionHandler } from "relay-runtime";
import environment from "../Environment";
// 2
const mutation = graphql`
  mutation LoginMutation($input: LoginInput!, $user_ip: String!) {
    login(input: $input, user_ip: $user_ip)
  }
`;
// 3
// eslint-disable-next-line camelcase
export default (email, password, user_ip, callback) => {
  // 4
  const variables = {
    user_ip,
    input: {
      email,
      password
    }
  };
  // 5
  commitMutation(environment, {
    mutation,
    variables,
    // 6
    // optimisticUpdater: proxyStore => {
    //   // ... you'll implement this in a bit
    // },
    // updater: proxyStore => {
    //   // ... this as well
    // },
    // 7
    onCompleted: (data, errors) => {
      callback(data, errors);
    },
    onError: (error) => {
      callback(
        undefined,
        Array.isArray(error.codes) ? error.codes : ["networkerror"]
      );
    }
  });
};
