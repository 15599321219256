import React from "react";
import PropTypes from "prop-types";

import "./PageHeader.scss";
import HeaderLinks from "../HeaderLinks";

class PageHeader extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    hideLinks: PropTypes.bool
  };

  static defaultProps = {
    hideLinks: false
  };

  render() {
    const { title, hideLinks } = this.props;
    return (
      <div className={`PageHeader ${hideLinks ? "PageHeaderHidden" : ""}`}>
        <h1>{title}</h1>
        {!hideLinks && <HeaderLinks />}
      </div>
    );
  }
}

export default PageHeader;
