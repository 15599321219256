/* eslint-disable react/prop-types */
import React from "react";
import { Route } from "react-router-dom";

import auth from "../../services/auth";

const ForceLogoutRoute = ({ component: Component, ...rest }) => {
  if (auth.isAuthenticated) {
    auth.signout();
  }
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default ForceLogoutRoute;
