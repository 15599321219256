/**
 * @generated SignedSource<<6959196efffad0254f785a33a35fefff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "roleID"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userID"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "roleID",
        "variableName": "roleID"
      },
      {
        "kind": "Variable",
        "name": "userID",
        "variableName": "userID"
      }
    ],
    "concreteType": "UserRole",
    "kind": "LinkedField",
    "name": "editUserRole",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserRoleEditMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserRoleEditMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4d2685a73dea6a7827048dcafeb628c8",
    "id": null,
    "metadata": {},
    "name": "UserRoleEditMutation",
    "operationKind": "mutation",
    "text": "mutation UserRoleEditMutation(\n  $roleID: String!\n  $userID: ID!\n) {\n  editUserRole(roleID: $roleID, userID: $userID) {\n    name\n  }\n}\n"
  }
};
})();

node.hash = "7595b9f5b7a6cb96cc5fa970a2addb26";

module.exports = node;
