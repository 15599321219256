/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { Container, Navbar, NavbarBrand, Button } from "reactstrap";
import * as Sentry from "@sentry/browser";
import Footer from "../Footer";

import "./AppBoundary.scss";

class AppBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { error } = this.state;
    // eslint-disable-next-line react/prop-types
    const { children } = this.props;
    if (error) {
      return (
        <div className="AppBoundary">
          <Navbar light color="light">
            <Container>
              <NavbarBrand href="/" className="mr-auto">
                <img
                  src="/img/TC-Full-Logo-3.png"
                  alt="TechCenter powered by Rokion and Prairie Machine"
                />
              </NavbarBrand>
            </Container>
          </Navbar>
          <Container className="Boundary">
            <h1>Error</h1>
            <p>
              An error has occurred and our support team has been notified. If
              you would like to provide additional details, please report your
              issue below.
            </p>
            <Button color="primary" onClick={() => Sentry.showReportDialog()}>
              Report Bug
            </Button>
            <Footer />
          </Container>
        </div>
      );
    }
    // when there's not an error, render children untouched
    return children;
  }
}

export default AppBoundary;
