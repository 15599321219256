/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { QueryRenderer } from "react-relay";
import graphql from "babel-plugin-relay/macro";

import LoadingDots from "../../components/LoadingDots";
import environment from "../../Environment";
import UserRoleForm from "../../components/Forms/UserRoleForm";

class UserRoleEdit extends React.PureComponent {
  static propTypes = {
    // user: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false
    };
  }

  render() {
    const { loading } = this.state;
    const { user, match } = this.props;
    const { userroleID } = match.params;

    return (
      <div className="Locations withSubtitle">
        <QueryRenderer
          environment={environment}
          query={graphql`
            query UserRoleEditQuery($role_id: String!) {
              userRole(id: $role_id) {
                name
                user_id
              }
              usersInternal {
                node_id
                first_name
                last_name
                email
                usergroup
              }
            }
          `}
          variables={{
            role_id: userroleID
          }}
          render={({ error, props }) => {
            if (error) {
              // do nothing
              return <div>error occurred</div>;
            }
            if (props) {
              return (
                <UserRoleForm
                  user={user}
                  usersInternal={props.usersInternal}
                  userRole={props.userRole}
                  loading={loading}
                />
              );
              // return (
              //   <div>
              //     <div>hello there</div>
              //     <div>{props.userRole.name}</div>
              //   </div>
              // );
            }
            return <LoadingDots loading />;
          }}
        />
      </div>
    );
  }
}

export default UserRoleEdit;
