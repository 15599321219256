import React from "react";
import Loadable from "react-loadable";
import PulseLoader from "react-spinners/PulseLoader";

const LoadingScreen = () => (
  <div className="PagePulseLoader">
    <PulseLoader loading color="#0000af" size={20} />
  </div>
);

export const TileDashboard = Loadable({
  loader: () => import("../containers/TileDashboard"),
  loading: LoadingScreen
});

export const Dashboard = Loadable({
  loader: () => import("../containers/Dashboard"),
  loading: LoadingScreen
});

export const FleetOverview = Loadable({
  loader: () => import("../containers/Fleet"),
  loading: LoadingScreen
});

export const ResourceDashboard = Loadable({
  loader: () => import("../containers/ResourceDashboard"),
  loading: LoadingScreen
});

export const RequestService = Loadable({
  loader: () => import("../containers/RequestService"),
  loading: LoadingScreen
});

export const Customers = Loadable({
  loader: () => import("../containers/Customers"),
  loading: LoadingScreen
});

export const CustomersAdd = Loadable({
  loader: () => import("../containers/Customers/CustomerAdd"),
  loading: LoadingScreen
});

export const CustomerEdit = Loadable({
  loader: () => import("../containers/Customers/CustomerEdit"),
  loading: LoadingScreen
});

export const Locations = Loadable({
  loader: () => import("../containers/Locations/index"),
  loading: LoadingScreen
});

export const LocationAdd = Loadable({
  loader: () => import("../containers/Locations/LocationAdd"),
  loading: LoadingScreen
});

export const LocationEdit = Loadable({
  loader: () => import("../containers/Locations/LocationEdit"),
  loading: LoadingScreen
});

export const UserAdd = Loadable({
  loader: () => import("../containers/Users/UserAdd"),
  loading: LoadingScreen
});

export const UserEdit = Loadable({
  loader: () => import("../containers/Users/UserEdit"),
  loading: LoadingScreen
});

export const ResourceAdd = Loadable({
  loader: () => import("../containers/Resources/ResourceAdd"),
  loading: LoadingScreen
});

export const ResourceEdit = Loadable({
  loader: () => import("../containers/Resources/ResourceEdit"),
  loading: LoadingScreen
});

export const InspectionEdit = Loadable({
  loader: () =>
    import("../containers/ResourceDashboard/Inspections/InspectionEdit"),
  loading: LoadingScreen
});

export const InspectionAdd = Loadable({
  loader: () =>
    import("../containers/ResourceDashboard/Inspections/InspectionAdd"),
  loading: LoadingScreen
});

export const NoteEdit = Loadable({
  loader: () => import("../containers/ResourceDashboard/Notes/NoteEdit"),
  loading: LoadingScreen
});

export const NoteAdd = Loadable({
  loader: () => import("../containers/ResourceDashboard/Notes/NoteAdd"),
  loading: LoadingScreen
});

export const ComponentEdit = Loadable({
  loader: () =>
    import("../containers/ResourceDashboard/Components/ComponentEdit"),
  loading: LoadingScreen
});

export const ComponentAdd = Loadable({
  loader: () =>
    import("../containers/ResourceDashboard/Components/ComponentAdd"),
  loading: LoadingScreen
});

export const LiveLync = Loadable({
  loader: () => import("../containers/LiveLync"),
  loading: LoadingScreen
});

export const Documents = Loadable({
  loader: () => import("../containers/Documents"),
  loading: LoadingScreen
});

export const DocumentEdit = Loadable({
  loader: () => import("../containers/Documents/DocumentEdit"),
  loading: LoadingScreen
});

export const ServicePage = Loadable({
  loader: () => import("../containers/Services"),
  loading: LoadingScreen
});

export const Softwares = Loadable({
  loader: () => import("../containers/Softwares"),
  loading: LoadingScreen
});

export const SoftwareAdd = Loadable({
  loader: () => import("../containers/Softwares/SoftwareAdd"),
  loading: LoadingScreen
});

export const SoftwareEdit = Loadable({
  loader: () => import("../containers/Softwares/SoftwareEdit"),
  loading: LoadingScreen
});

export const ResourceSoftwareAdd = Loadable({
  loader: () =>
    import("../containers/ResourceDashboard/Softwares/ResourceSoftwareAdd"),
  loading: LoadingScreen
});

export const ResourceSoftwareEdit = Loadable({
  loader: () =>
    import("../containers/ResourceDashboard/Softwares/ResourceSoftwareEdit"),
  loading: LoadingScreen
});

export const UsersInternal = Loadable({
  loader: () => import("../containers/UsersInternal"),
  loading: LoadingScreen
});

export const UserInternalAdd = Loadable({
  loader: () => import("../containers/UsersInternal/UserInternalAdd"),
  loading: LoadingScreen
});

export const UserInternalEdit = Loadable({
  loader: () => import("../containers/UsersInternal/UserInternalEdit"),
  loading: LoadingScreen
});

export const Parts = Loadable({
  loader: () => import("../containers/Parts"),
  loading: LoadingScreen
});

export const KnowledgeBase = Loadable({
  loader: () => import("../containers/KnowledgeBase"),
  loading: LoadingScreen
});

export const Article = Loadable({
  loader: () => import("../containers/Article"),
  loading: LoadingScreen
});

export const ArticleAdd = Loadable({
  loader: () => import("../containers/Article/ArticleAdd"),
  loading: LoadingScreen
});

export const ArticleEdit = Loadable({
  loader: () => import("../containers/Article/ArticleEdit"),
  loading: LoadingScreen
});

export const Case = Loadable({
  loader: () => import("../containers/Cases/Case"),
  loading: LoadingScreen
});

export const CaseAdd = Loadable({
  loader: () => import("../containers/Cases/CaseAdd"),
  loading: LoadingScreen
});

export const CaseEdit = Loadable({
  loader: () => import("../containers/Cases/CaseEdit"),
  loading: LoadingScreen
});

export const CasesPage = Loadable({
  loader: () => import("../containers/Cases/CasesPage"),
  loading: LoadingScreen
});

export const EnterpriseSearch = Loadable({
  loader: () => import("../containers/EnterpriseSearch"),
  loading: LoadingScreen
});

export const ResourcePasswordAdd = Loadable({
  loader: () => import("../containers/ResourcePassword/ResourcePasswordAdd"),
  loading: LoadingScreen
});
export const ResourcePasswordEdit = Loadable({
  loader: () => import("../containers/ResourcePassword/ResourcePasswordEdit"),
  loading: LoadingScreen
});

export const Alerts = Loadable({
  loader: () => import("../containers/Alerts"),
  loading: LoadingScreen
});

export const AlertAdd = Loadable({
  loader: () => import("../containers/Alerts/AlertAdd"),
  loading: LoadingScreen
});

export const AlertView = Loadable({
  loader: () => import("../containers/Alerts/AlertView"),
  loading: LoadingScreen
});

export const AlertEdit = Loadable({
  loader: () => import("../containers/Alerts/AlertEdit"),
  loading: LoadingScreen
});

export const ResetPassword = Loadable({
  loader: () => import("../containers/Login/ResetPassword"),
  loading: LoadingScreen
});

export const NewPassword = Loadable({
  loader: () => import("../containers/Login/NewPassword"),
  loading: LoadingScreen
});

export const FourOhFour = Loadable({
  loader: () => import("../containers/404"),
  loading: LoadingScreen
});

export const FourOhThree = Loadable({
  loader: () => import("../containers/403"),
  loading: LoadingScreen
});

export const FourHundred = Loadable({
  loader: () => import("../containers/400"),
  loading: LoadingScreen
});
