import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/stable";
import React from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/browser";
import "bootstrap/dist/css/bootstrap.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import ReactGA from "react-ga4";
import "./index.css";
import App from "./App";
// import registerServiceWorker from "./registerServiceWorker";

Sentry.init({
  dsn: "https://8e22401e73cc4dc3b6ae8b08f06fc53b@sentry.io/3078994",
  environment: process.env.NODE_ENV
});

ReactGA.initialize("G-YX10QNRQ5Z");

// Log initial page view
ReactGA.send({
  hitType: "pageview",
  page: window.location.pathname + window.location.search
});

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
// registerServiceWorker();
