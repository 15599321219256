import React from "react";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga4";
import PropTypes from "prop-types";

// Logs PageViews
class GoogleAnalytics extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  UNSAFE_componentWillUpdate({ location, history }) {
    const { location: prevLocation } = this.props;
    if (
      location.pathname === prevLocation.pathname &&
      location.search === prevLocation.search
    ) {
      // don't log identical link clicks (nav links likely)
      return;
    }

    if (history.action === "PUSH") {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search
      });
    }
  }

  render() {
    return null;
  }
}

export default withRouter(GoogleAnalytics);
