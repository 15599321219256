/** @jsxImportSource @emotion/react */
import React from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Container, FormGroup, Input, Label, Button } from "reactstrap";
import { css } from "@emotion/react";
import { publicIpv4 } from "public-ip";
import axios from "axios";

import LoadingDots from "../../components/LoadingDots";
import Alert from "../../components/Alert";
import auth from "../../services/auth";
import LoginMutation from "../../mutations/LoginMutation";
import InputFeedback from "../../components/InputFeedback";
import "./Login.scss";

class Login extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    this.emailInput = null;
    this.passwordInput = null;
    this.loaderCSS = css`
      text-align: center;
    `;

    const { history, location } = this.props;

    // Display message once if it exists
    this.state = {
      email: "",
      password: "",
      redirectToReferrer: false,
      errors: [],
      loading: false,
      healthcheck: true,
      maintenance: false,
      alertMessage: null
    };
    if (props.location.state) {
      this.state.alertMessage = props.location.state.alertMessage;
      history.replace({
        pathname: location.pathname,
        state: {}
      });
    }
  }

  componentDidMount() {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/health/case`)
      .then((res) => {
        const OK = res.status >= 200 && res.status < 300;
        this.setState(
          {
            healthcheck: false,
            maintenance: !OK
          },
          () => {
            if (OK) {
              // Add event listeners because of autofill on iOS not bubbling
              this.emailInput.addEventListener("change", this.onChange);
              this.passwordInput.addEventListener("change", this.onChange);
            }
          }
        );
      })
      .catch((err) => {
        // eslint-disable-next-line
        console.log(err);
        this.setState({
          healthcheck: false,
          maintenance: true
        });
      });
  }

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.login();
    }
  };

  onChange = (e) => {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value
    });
  };

  login = async () => {
    this.setState({ errors: [], loading: true });
    const { email, password } = this.state;
    let userIp = "0.0.0.0";
    try {
      userIp = await publicIpv4();
    } catch (err) {
      // Couldn't get IP address
    }
    LoginMutation(email, password, userIp, (data, errors) => {
      let redirectToReferrer = false;
      let newErrors = [];
      if (errors) {
        newErrors = errors;
      } else {
        auth.authenticate(data.login);
        redirectToReferrer = true;
      }
      this.setState({ loading: false, redirectToReferrer, errors: newErrors });
    });
  };

  render() {
    const { from } = this.props.location.state || { from: { pathname: "/" } }; // eslint-disable-line

    const {
      redirectToReferrer,
      email,
      password,
      errors,
      loading,
      healthcheck,
      maintenance,
      alertMessage
    } = this.state;

    if (redirectToReferrer) {
      if (typeof from !== "undefined") {
        return <Redirect to={from} />;
      }
      return <Redirect to="/" />;
    }

    if (healthcheck) {
      return null;
    }

    if (maintenance) {
      return (
        <Container className="LoginScreen">
          <div style={{ maxWidth: "unset" }}>
            <h1 css={this.loaderCSS}>
              <img src="/img/TC-Full-Logo-3.png" alt="Prairie Machine" />
            </h1>

            <div style={{ marginTop: "20px" }}>
              <h1>Techcenter is presently undergoing maintenance.</h1>
            </div>
          </div>
        </Container>
      );
    }

    return (
      <Container className="LoginScreen">
        <div>
          <h1 css={this.loaderCSS}>
            <img src="/img/TC-Full-Logo-3.png" alt="Prairie Machine" />
          </h1>
          <FormGroup>
            <Label htmlFor="email">Email</Label>
            <Input
              type="email"
              name="email"
              id="email"
              value={email}
              onChange={this.onChange}
              onBlur={this.handleChange}
              onKeyPress={this.handleKeyPress}
              required
              innerRef={(input) => (this.emailInput = input)}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="password">Password</Label>
            <Input
              type="password"
              name="password"
              id="password"
              value={password}
              onChange={this.onChange}
              onBlur={this.handleChange}
              onKeyPress={this.handleKeyPress}
              required
              innerRef={(input) => (this.passwordInput = input)}
            />
          </FormGroup>
          <FormGroup>
            {errors.includes("doesnotexist") && (
              <InputFeedback valid={false}>
                This account does not exist, please contact Rokion for details.
              </InputFeedback>
            )}
            {errors.includes("unauthorized") && (
              <InputFeedback valid={false}>
                Your email and password combination are not valid.
              </InputFeedback>
            )}
            {errors.includes("needsnewpassword") && (
              <InputFeedback valid={false}>
                You require a new password before you can login. Please check
                your email on how to proceed. If you did not receive an email,
                please contact&nbsp;
                <a href="mailto:service@prairiemachine.com">
                  service@prairiemachine.com
                </a>
              </InputFeedback>
            )}
            {errors.includes("networkerror") && (
              <InputFeedback valid={false}>
                There is trouble connecting to the server. Check your internet
                connection then try again.
              </InputFeedback>
            )}
            {errors.includes("disabled") && (
              <InputFeedback valid={false}>
                This account is disabled. Please contact your administrator to
                regain access.
              </InputFeedback>
            )}
            {errors.includes("newdisabled") && (
              <InputFeedback valid={false}>
                This account has not logged into TechCenter within the last six
                months and has been disabled. Please contact your administrator
                to regain access.
              </InputFeedback>
            )}
          </FormGroup>
          <div>
            <LoadingDots loading={loading} />
          </div>
          <Button color="primary" className="mt-3" block onClick={this.login}>
            Login
          </Button>
          <div className="ResetLinkContainer">
            <a href="/reset" className="ResetLink">
              Reset Password
            </a>
          </div>
        </div>
        {alertMessage && <Alert message={alertMessage} color="success" />}
      </Container>
    );
  }
}

export default Login;
